/* @font-face {
    font-family: 'Averta-Bold';
    font-weight: 900;
    src: local('Averta'), url(./Assets/fonts/Averta/Muli-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Averta';
    font-weight: 400;
    src: local('Averta'), url(./Assets/fonts/Averta/Muli-Regular.ttf) format('truetype');
} */

@font-face {
    font-family: 'Averta-Bold';
    src: local('Averta-Bold'), url(./Assets/fonts/averta-bold.otf) format('opentype');
}

@font-face {
    font-family: 'Averta';
    src: local('Averta'), url(./Assets/fonts/averta.otf) format('opentype');
}

@font-face {
    font-family: 'Averta-Black';
    src: local('Averta-Black'), url(./Assets/fonts/averta-black.otf) format('opentype');
}

#root {
    white-space: pre-line;
}

.dana-activation-title {
    font-family: Averta;
    font-weight: 900;
    font-size: 20px;
}

.dana-activation-desc {
    font-family: Averta;
    font-weight: 400;
    font-size: 16px;
}

.dana-activation-btn {
    background-color: #E10009 !important;
    color: white !important;
    font-family: Averta !important;
    font-weight: 900 !important;
    font-size: 15px !important;
}

body {
    overflow-x: hidden !important;
}

.color-red {
    color: #E10009 !important;
}

.bg-maroon {
    background-color: #E10009 !important;
}

.container-custom {
    min-height: 100vh !important;
    /* max-width: 100% !important; */
    background-color: #E5E5E5 !important;
}

.phonenumber {
    font-size: 11px !important;
}

.is-valid {
    background-image: url('./Assets/valid.png') !important;
    background-position: 98% !important;
    background-size: 20px !important;
}

.visa {
    background-image: url('./Assets/visa.png') !important;
    background-position: 93% !important;
    background-size: 40px !important;
    background-repeat: no-repeat, no-repeat !important;
}

.mastercard {
    background-image: url('./Assets/mastercard.png') !important;
    background-position: 93% !important;
    background-size: 40px !important;
    background-repeat: no-repeat, no-repeat !important;
}

.jcb {
    background-image: url('./Assets/jcb.png') !important;
    background-position: 93% !important;
    background-size: 40px !important;
    background-repeat: no-repeat, no-repeat !important;
}

.is-invalid {
    background-image: url('./Assets/invalid.png') !important;
    background-position: 98% !important;
    background-size: 20px !important;
}

/* .visa {
    background-image: url('./Assets/visa.png') !important;
    background-position: 90% !important;
    background-size: 40px !important;
} */

a {
    text-decoration: none !important;
}

.bg-linear {
    background: linear-gradient(90deg, #FFEDD8 0%, #FFFDD4 99.94%) !important;
}

/* .form-control {
    border: 0px !important;
} */

.text-hours {
    font-family: 'Averta-Extra-Bold' !important;
    color: #006BE9 !important;
}

.content {
    background-color: #FFFFFF !important;
}

.header {
    background-color: #FFFFFF !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px 8px 0px 0px !important;
}

.font-averta {
    font-family: 'Averta' !important;
}

.font-averta-bold {
    font-family: 'Averta-Bold' !important;
}

.font-averta-black {
    font-family: 'Averta-Black' !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.font-size-12 {
    font-size: 12px !important;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-22 {
    font-size: 22px !important;
}

.text-kanggo-black {
    color: #252525 !important;
}

.text-kanggo-grey {
    color: #9a9a9a !important;
}

.gap {
    margin-top: 16px !important;
}

.img-fluid {
    max-width: 120px !important;
}

.border-list {
    /* cursor: pointer; */
    border-bottom: 1px solid #E8E8E8 !important;
}

.border-dash {
    /* cursor: pointer; */
    border-bottom: 1px dashed #D8CFCD !important;
}

.font-inter {
    font-family: 'Inter', sans-serif !important;
}

.text-primary-custom {
    color: #7C7C7C !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-700 {
    font-weight: 700 !important;
    font-size: 24px !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fw-300 {
    font-weight: 300 !important;
}

.fs-normal {
    font-style: normal !important;
}

.price {
    font-size: 24px !important;
}

.currency {
    font-size: 18px !important;
    margin-right: 4px !important;
}

.text-amount {
    font-size: 17px !important;
}

.text-how-to-pay {
    font-size: 17px !important;
}

.text-invoice {
    font-size: 12px !important;
}

.invoice {
    font-size: 12px !important;
}

.category-title {
    display: block !important;
    font-size: 14px !important;
    font-family: 'Averta-Bold' !important;
    color: #252525 !important;
}

.category-description {
    font-size: 12px !important;
    font-family: 'Averta' !important;
    color: #868686 !important;
}

.additional-info {
    font-size: 11px !important;
}

.pay-flow {
    font-size: 14px !important;
}

.btn-footer {
    height: 49px !important;
    background-color: #D9272E !important;
}

.tnc {
    font-family: Averta-Regular;
    font-size: 13px;
    letter-spacing: 0em;
    color: #2F2F2F !important;
}

.link-tnc {
    font-family: Averta-Semibold;
    font-size: 13px;
    letter-spacing: 0em;
    text-align: center;
    color: #E10009 !important;
}

.text-maroon {
    color: #E10009 !important;
}

.border-btn-tab {
    border-bottom: 4px solid #D9272E !important;
}

.btn-light {
    background-color: #FFFFFF !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}

.btn-light:hover {
    background-color: #FFFFFF !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    /* border-color: #FFFFFF !important; */
}

.btn-pay {
    background-color: #D9272E !important;
    border-color: #D9272E !important;
}

.text-pay {
    color: #FFFFFF !important;
}

/* .btn-link:cl {
    background-color: #f31d1d !important;
} */

/* .row>* {
    padding-right: 0 !important;
    padding-left: 0 !important;
} */

.footer-text-payment-method {
    font-size: 12px !important;
    color: #727272;
    /* font-family: Averta !important */
}

.footer-payment-method {
    font-size: 14px !important;
    color: #000000;
    font-weight: 600;
    /* font-family: Averta !important */
}

.logo-payment {
    width: 56px !important;
    height: 56px !important;
    object-fit: contain;
}

.list {
    padding-top: 1.3rem !important;
    padding-bottom: 1.3rem !important;
}

.btn-danger {
    background-color: #E10009 !important;
    border-color: #E10009 !important;
    height: 43px !important;
    font-size: 13.4px !important;
}

.modal-open {
    padding-right: 0px !important;
}

.react-switch-handle {
    box-shadow: none !important;
}


.title {
    font-size: 1vw !important;
    max-width: 10px !important;
    /* line-height: 122px !important; */

    color: #000000 !important;
}

.border-header {
    border: 1px solid #C0C0C0 !important;
    transform: rotate(90deg) !important;
    width: 56px !important;
    height: 0px !important;
}

.bs-gutter-x-0 {
    --bs-gutter-x: 0 !important;
}

.bs-gutter-y-0 {
    --bs-gutter-y: 0 !important;
}

.btn-x {
    cursor: pointer;
    /* margin-top: -5px !important; */
}

.form-control:focus {
    box-shadow: none !important;
    border-color: #A5A5A6 !important;
}

.form-control {
    border-radius: 12px !important;
    border: 1px solid #A5A5A6 !important;
    padding: 10px 12px 10px 12px !important;
}

.btn-gradient-primary {
    /* background: var(--gradient-red-red, linear-gradient(90deg, #891313 0%, #D92727 99.97%)) !important; */
    background-color: #E10009 !important;
}

.bg-gradient-countdown {
    background: var(--gradient-red-red-01, linear-gradient(90deg, #FDD 0%, #FFEFDB 99.97%)) !important;
    /* background-color: #E10009 !important; */
}

.radius-50 {
    border-radius: 50px !important;
}

.border-bottom {
    border-bottom: 1.5px solid #E8E8E8 !important;
}

.border-right {
    border-right: 1.5px solid #E8E8E8 !important;
    border-end-end-radius: 0px !important;
}

.payment-expired {
    /* background-color: rgba(217, 39, 46, 0.25) !important; */
    color: #FFFFFF !important;
    font-size: 12px !important;
    /* height: 28px !important; */
}

.border-btn-tab {
    border-bottom: 4px solid #E10009 !important;
}

.btn-outline-danger {
    color: #E10009 !important;
    border-color: #E10009 !important;
    height: 43px !important;
    font-size: 13.4px !important;
}

.btn-sm {
    height: 30px !important;
}

.btn-outline-danger:hover {
    color: #FFFFFF !important;
    border-color: #E10009 !important;
    background-color: #E10009 !important;
}

.btn-outline-danger:focus {
    box-shadow: 0 0 0 0.02rem #E10009 !important;
}

.account-number-text {
    font-size: 15px !important;
    font-family: Averta !important;
    color: #676767 !important;
}

.account-name-text {
    font-size: 16px !important;
    font-family: 'Averta-Bold' !important;
}

.border-dash {
    border-bottom: 1px dashed #D8CFCD;
}

.account-number-nominal {
    font-size: 24px !important;
    font-family: Averta !important;
    color: #000000 !important;
}

.btn-disabled {
    background-color: #e65d61 !important;
    cursor: not-allowed !important
}

.text-payment-status {
    font-size: 24px !important;
}

.footer {
    background-color: #ffffff !important;
    border-color: #ffffff !important;
    /* box-shadow: -1px -13px 5px -9px rgba(0, 0, 0, 0.75) !important;
    -webkit-box-shadow: -1px -13px 5px -9px rgba(0, 0, 0, 0.75) !important;
    -moz-box-shadow: -1px -13px 5px -9px rgba(0, 0, 0, 0.75) !important; */
}

.red-gradient {
    background: var(--gradient-red-red-primary-button, linear-gradient(116deg, #D9272D 16.15%, #EB4600 83.24%));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 600px) {
    .img-fluid {
        max-width: 110px !important;
    }

    .title {
        font-size: 11px !important;
    }

    .wrap-title {
        /* width: 10px !important; */
        overflow: hidden !important;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;

    }

    .header-action {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
}